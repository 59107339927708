import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../layout'
import {
  UserInfo,
  Disqus,
  PostTags,
  SEO,
  HTML,
  SocialBlocks,
  CTA,
} from '../components'
import config from '../../data/SiteConfig'
import rehypeReact from 'rehype-react'
import ImageGallery from 'react-image-gallery'
import styled from 'styled-components'
import urljoin from 'url-join'
import { ShareIcons } from '../components/ShareIcons/ShareIcons'
import { media } from '@caldera-digital/theme'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const StyledBlogImage = styled.img`
  max-width: 100%;
  max-height: 570px;
  object-fit: cover;
  margin: 1.5rem auto;
  display: block;

  ${media.forSmallOnly`
    img {
      max-height: 200px;
    }
  `}
`

const Gallery = ({ images }) => {
  const parsedImages = JSON.parse(images)

  const readyImages = parsedImages.reduce((accu, item) => {
    if (item.original) {
      // I know this is ugly, but don't think it's going to break anything
      const thumbnail = item.original.split('/')
      thumbnail[6] = 'c_fill,g_face,h_150,q_auto,w_250'

      accu.push({
        thumbnail: thumbnail.join('/'),
        originalAlt: item.alt,
        thumbnailAlt: item.alt,
        originalTitle: item.title,
        thumbnailTitle: item.title,
        ...item,
      })
    }

    return accu
  }, [])

  return <ImageGallery items={readyImages} />
}

const MediumImage = props => {
  return (
    <Zoom zoomMargin={20} wrapStyle={{ width: '100%', outline: 'none' }}>
      <StyledBlogImage {...props} />
    </Zoom>
  )
}

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { 'gallery-component': Gallery, img: MediumImage },
}).Compiler

const PostWrapper = styled.div`
  display: flex;

  @media (max-width: 1240px) {
    flex-direction: column;
  }
`
const CoverImage = styled.img``
const Title = styled.h1``
const DateField = styled.p``
const PostContainer = styled.div`
  max-width: 760px;
  border: 5px solid #e5e5e5;
  padding: 2rem;
  font-family: ${props => props.theme.postFont};
  margin-right: 3rem;

  ${CoverImage} {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    margin: 0 0 2rem;
  }

  ${Title} {
    text-align: center;
    font-size: 2.25rem;
    margin-bottom: 0.25rem;
    font-family: ${props => props.theme.defaultFont};
  }

  ${DateField} {
    color: ${props => props.theme.secondaryColor};
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
    font-family: ${props => props.theme.defaultFont};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    font-family: ${props => props.theme.postFont};
  }

  p {
    line-height: 2;
  }

  @media (max-width: 1240px) {
    max-width: 100%;
    margin: 0 0 2rem 0;
  }

  ${media.forSmallOnly`
    padding: 1rem;

    ${Title} {
      font-size: 2rem;
    }

    ${DateField} {
      font-size: 1.25rem;
    }
  `}
`

const SideContent = styled.div`
  flex: 1;
`

export default class PostTemplate extends React.Component {
  render() {
    const {
      data: {
        markdownRemark: postNode,
        settingsYaml: { introBlurb, outroBlurb },
      },
      pageContext,
    } = this.props
    const { slug } = pageContext
    const post = postNode.frontmatter
    if (!post.id) {
      post.id = slug
    }
    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID
    }
    const url = urljoin(config.siteUrl, config.pathPrefix, slug)

    return (
      <Layout showFooterCTA={false}>
        <Helmet>
          <title>{`${post.title} | ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postPath={slug} postNode={postNode} postSEO />

        <PostWrapper>
          <PostContainer>
            <CoverImage src={post.cover} alt={post.coverAlt} />
            <Title>{post.title}</Title>
            <DateField>{post.date}</DateField>

            <HTML html={introBlurb} />
            <div>{renderAst(postNode.htmlAst)}</div>
            <HTML html={outroBlurb} />
            <div className="post-meta">
              <CTA />
              <ShareIcons
                url={url}
                copy={postNode.excerpt}
                description={postNode.excerpt}
                title={post.title}
                image={post.cover}
              />
              <PostTags tags={post.tags} />
            </div>
            <UserInfo config={config} />
            <Disqus postNode={postNode} />
          </PostContainer>
          <SideContent>
            <SocialBlocks />
          </SideContent>
        </PostWrapper>
      </Layout>
    )
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      timeToRead
      excerpt
      id
      frontmatter {
        title
        cover
        coverAlt
        date(formatString: "MMMM DD YYYY")
        category
        tags
      }
      fields {
        slug
        date
      }
    }
    settingsYaml {
      introBlurb
      outroBlurb
    }
  }
`
